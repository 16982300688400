import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { withRouter } from 'react-router';
import AppContext from '../components/AppContext';
import { capitalizeName, getMenusAndSubMenuDetails, getStringValue } from "../utils/common";
import { menuItemsDetais } from "../utils/sidebar";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import { header_languages, updateLocalStorageObject } from "../AppConfig";
import { useTranslation } from 'react-i18next';
import '../i18n';
import { Button } from '@themesberg/react-bootstrap';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import PdfComponent from './PdfComponent';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData, encryptData } from '../utils/crypto';
import AvatarWithDropdown from './AvatarWithDropdown';

const HeaderDropdown = ({ ...props }) => {
    const contextData = useContext(AppContext);

    const [userName, setUserName] = useState("");

    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [selectedComapny, setSelectedCompany] = useState({});
    const [t, i18n] = useTranslation();
    const [page2, setPage2] = useState("");
    const [currentWebsiteId, setCurrentWebsiteId] = useState(0);
    const [isEditWebsiteEnabled, setIsEditWebsiteEnabled] = useState(false)

    useEffect(() => {

        getUserName();
        // if (Array.isArray(contextData.menu) && contextData.menu.length === 0) {
        localStorage.setItem("IsPageRefresh", "1");
        //     localStorage.setItem("PageURL", window.location.href);
        // }
        getSelectedLanguage();
        getCompanyData();

    }, []);

    useEffect(() => {
        const packageName = JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["packageName"];

        if (contextData.companyId > 0) {

            if (contextData.selectedCompanyPackage == "" && packageName) {
                contextData.changeSelectedCompanyPackage(packageName);
            }
            else if (!packageName) {
                request(baseURL + `/CommonV2/GetPackageDetails?companyId=${contextData.companyId}`,
                    METHODS.GET,
                    {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        "content-type": "application/json"
                    }
                ).then((res) => {
                    if (res) {
                        updateLocalStorageObject("selectedCompanyData", "packageName", res, contextData.cryptoKey) //localstorage key, node's key, value and crytoKey if it is encrypted
                        contextData.changeSelectedCompanyPackage(res)
                    }
                })

            } else {
                updateLocalStorageObject("selectedCompanyData", "packageName", packageName, contextData.cryptoKey) //localstorage key, node's key, value and crytoKey if it is encrypted
                contextData.changeSelectedCompanyPackage(packageName)
            }

        }
    }, [contextData.companyId])


    const getUserName = () => {
        let fname = contextData.firstName ? contextData.firstName : decryptData(localStorage.getItem("firstName"), contextData.cryptoKey);
        let lname = contextData.lastName ? contextData.lastName : decryptData(localStorage.getItem("lastName"), contextData.cryptoKey);
        setUserName(fname + " " + lname);
    };

    const getCompanies = () => {
        let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
        let userId = decryptData(localStorage.getItem("userId"), contextData.cryptoKey);
        if (token && userId) {
            axios
                .get(
                    `${process.env.REACT_APP_API_KEY}/Auth/FetchUserDetail`,
                    {
                        params: {
                            userId: userId
                        },
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    },
                )
                .then(response => {
                    if (response && response.data && response.data.resultData) {
                        contextData.setCompanies(response.data.resultData);
                        contextData.changeUserToken(decryptData(localStorage.getItem("token"), contextData.cryptoKey));
                        contextData.changeUerId(parseInt(decryptData(localStorage.getItem("userId"), contextData.cryptoKey)));
                        contextData.changeEmail(decryptData(localStorage.getItem('email'), contextData.cryptoKey));
                        contextData.changeCompanyId(JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"]);
                        let companyId = JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"];
                        if (response.data.resultData && Array.isArray(response.data.resultData) && response.data.resultData.length > 0) {
                            if (companyId) {
                                const selectedComapnyData = response.data.resultData.find(dta => dta.companyId === companyId);
                                if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0) {
                                    setSelectedCompany(selectedComapnyData);
                                }
                                // // const val = JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey));
                                // if (selectedComapnyData.companyId === JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"]) {
                                //     setPkgData(JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["packageName"])
                                //     contextData.changeSelectedCompanyPackage(JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["packageName"])
                                // }
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    }

    const getSelectedLanguage = () => {
        let language;
        if (contextData.languagePreference) {
            const selectedLanguagData = header_languages.find(dta => dta.id === contextData.languagePreference);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0)
                language = selectedLanguagData;
        }
        else if (i18n.language) { // In case of page refresh 
            const selectedLanguagData = header_languages.find(dta => dta.id === i18n.language);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0) {
                language = selectedLanguagData;
            }
        }
        setSelectedLanguage(language);
    };


    const getCompanyData = () => {
        if (contextData.companies && Array.isArray(contextData.companies) && contextData.companies.length > 0) {
            if (contextData.companyId) {
                const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
                if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0) {
                    setSelectedCompany(selectedComapnyData);

                    updateLocalStorageObject("selectedCompanyData", "companyId", selectedComapnyData.companyId, contextData.cryptoKey)
                    updateLocalStorageObject("selectedCompanyData", "companyName", selectedComapnyData.companyName, contextData.cryptoKey)
                }
            }
        }
        else {
            getCompanies();
        }
    };

    const clearLocalStorageData = () => {
        const keysToRemove = [
            "EventId", "PresentationId", "webcastEventId", "ManageusersEventId",
            "WebsiteProfilesIntelConfigId", "WebsiteListWebsiteId", "alertWebsiteId",
            "SelectedDatePicker", "EventCalendarWebsiteId", "EventCalendarProfileId",
            "AlertStatsWebsiteId", "ArchiveAlertWebsiteId", "ManualAlertWebsiteId",
            "ManagesubscriptionWebsiteId", "AlertSummaryWebsiteId", "sorting",
            "SelectedEventId", "eventsWebsiteData", "languageData", "websiteData",
            "profileData", "AllprofileData", "SelectedDatePicker_website",
            "SelectedDatePicker_IR-tools-anaytics", "SelectedDatePicker_alert",
            "TnfWebsiteListWebsiteId", "TnfWebsiteData", "TnfWebsiteProfilesIntelConfigId",
            "TnfAllProfileData", "selectedCompanyData","TnfProfileData"
        ];
        keysToRemove.forEach(key => localStorage.removeItem(key));
    };

    const onComapnyDropdownChange = (data) => {
        const companyId = data.companyId;

        // To avoid event change if same client is selected
        if (companyId != contextData.companyId) {
            setSelectedCompany(data);
            contextData.changeSelectedWebsite({});
            contextData.changeSelectedProfile({});
            contextData.changeSelectedTab("");
            contextData.changeCompanyId(companyId);
            contextData.changeWebsiteId(0);
            localStorage.removeItem("selectedTab");
            contextData.changePresentationId(0);
            contextData.changePresentation({});
            contextData.changeEventId({});
            contextData.changeSelectedCompanyPackage("")

            clearLocalStorageData(); //clear items in localStorage

            updateLocalStorageObject("selectedCompanyData", "companyId", companyId, contextData.cryptoKey)
            updateLocalStorageObject("selectedCompanyData", "companyName", data.companyName, contextData.cryptoKey)

            localStorage.setItem("IsPageRefresh", "0");  // set  page refresh indicator to 0 on side bar click event

        }
        if (companyId === 27181) {
            localStorage.setItem("isDemoClient", 1);
        }
        else {
            localStorage.removeItem("isDemoClient");
        }
    }

    const logout = () => {
        request(baseURL + `/Auth/Logout?Token=${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
            METHODS.POST)
            .then((res) => {
                localStorage.clear();         // Clear All Entries from Local Stoarge
                sessionStorage.clear();       // Clear All Entries from Session Stoarge
                props.history.push("/login"); // Redirect to Login Page
                window.location.reload();
            })
            .catch((err) => {
                console.error("Error: " + err);
            })
    }

    return (
        <>
            {
                (contextData.companies && Array.isArray(contextData.companies) && contextData.companies.length > 0) &&
                <div className="media d-flex align-items-center">
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center btn-toolbar profile-dropdown">

                            {
                                contextData.companies.length > 1 ?

                                    <DropdownWithIconsComponent
                                        isDropdownDisabled={localStorage.getItem("selectedCompanyID") ? true : false}
                                        dropdownData={contextData.companies}
                                        arrayValue="companyName"
                                        selectedDropdown={selectedComapny}
                                        onChange={onComapnyDropdownChange}
                                        showLabel={false}
                                        showIconInDropdownItem={false}
                                        isPrimaryImage={false}
                                        customClassName="text-overflow"
                                    />
                                    : <>{contextData.companies[0].companyName}</>
                            }
                        </div>
                    </div>
                </div>
            }

            <div className="media d-flex align-items-center">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center icon-nav-list svg-icon-nav-list">
                        <PdfComponent iscard={false}></PdfComponent>
                    </div>
                </div>
            </div>

            <AvatarWithDropdown
                userName={capitalizeName(getStringValue(userName.toUpperCase()))}
                packageData={contextData.selectedCompanyPackage}
                logout={logout}
            />
        </>
    );
};

export default withRouter(HeaderDropdown);