import { Row, Col, Card, Button, Image } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import ServiceCenterComponent from '../components/ServiceCenterComponent';
import ServiceCenterSummaryComponent from '../components/ServiceCenterSummaryComponent';
import ServiceCenterRequestMoreHoursComponent from '../components/ServiceCenterRequestMoreHoursComponent';
import ServiceCenterReportDetail from '../components/ServiceCenterReportDetailComponent';
import ChartRenderer from '../components/ChartRenderer';
import DashboardItem from '../components/DashboardItem';
import { isMobile } from "react-device-detect";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import { DEFAULT_PAGE_NO } from "../AppConfig";
import { getServiceCenterReports, getServiceCenterReportDetails } from "../stubs/ComponentJson/ServiceCenter";
import YourInvestisTeam from '../components/YourInvestisTeam';
import Search from "../components/Search";
import Filters from "../components/Filters";
import cubejs from '@cubejs-client/core';
import Loader from '../components/VoltLoader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import AppContext from '../components/AppContext';
import { QueryRenderer } from '@cubejs-client/react';
import moment from "moment-timezone";
import CommonHeader from "../components/CommonHeader"
import { Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ChartRendererTable from '../components/ChartRendererTable';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import ModalComponent from "../components/VoltModal";
import { baseURL, METHODS } from '../apis/utilities/constant';
import request from '../apis/request';
import RGL, { WidthProvider } from 'react-grid-layout';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const ServiceCentrePages = () => {
  const [t] = useTranslation();
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const contextData = useContext(AppContext);
  const TABLE_TOTAL_ROWS = 6;
  const [selectedWorkorderCompanyTemp, setSelectedWorkorderCompanyTemp] = useState({});
  const [selectedWorkorderCompany, setSelectedWorkorderCompany] = useState({});
  const [selectedWorkordercompanyid, setSelectedWorkordercompanyid] = useState(0);
  const [reportData, setreportData] = useState([]);
  const [reportDetailData, setreportDetailData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [detailOffset, setDetailOffset] = useState(0);
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [detailPageNo, setDetailPageNo] = useState(DEFAULT_PAGE_NO);
  const [searchString, setSearchString] = useState("");
  const [detailSearchString, setDetailSearchString] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedTempYear, setSelectedTempYear] = useState();
  const [items, setItems] = useState();
  // const [clientsData, setClientsData] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [showError, setshowError] = useState(false);
  const [showBlurLoader, setShowBlurLoader] = useState(false);
  const [showReportDetailModel, setShowReportDetailModel] = useState(false);
  const [reportDetailsData, setReportDetailsData] = useState(undefined);
  const [hasDataLoaded, setHasDataLoaded] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [searchDataPopup, setSearchDataPopup] = useState("");
  const [searchWaterMark, setSearchWaterMark] = useState();
  const [searchWaterMarkPopup, setSearchWaterMarkPopup] = useState("Search WO/WO name");
  const [isFromPop, setIsFromPop] = useState(false);

  const [allData, setAllData] = useState([]);

  const [filterShow, setFilterShow] = useState(false);
  const [dropdownYearList, setDropdownYearList] = useState([]);
  const [selectedDropdownYearItem, setSelectedDropdownYearItem] = useState({ Year: 'All' });
  const [selectedFilterItem, setselectedFilterItem] = useState({});

  const [popupURL, setPopupURL] = useState("");



  const onCloseAlert = () => {
    setshowSuccess(false);
    setshowError(false);
  }

  useEffect(() => {
    const syear = (selectedYear && selectedYear.id !== "All") ? selectedYear.year : null;
    let dta = selectedWorkordercompanyid ? getServiceCenterReports(selectedWorkordercompanyid, contextData.companyId, searchString, syear, offset) : null;
    setreportData(dta);
  }, [selectedWorkordercompanyid, searchString, offset, selectedYear]);

  useEffect(() => {
    let resultData = []
    if (allData.uniqueYears !== null && allData.uniqueYears?.length) {
      resultData = [
        { Year: "All" },
        ...allData.uniqueYears.map(Year => ({ Year: Year }))
      ]
    }
    setDropdownYearList(resultData)
    setFilterShow(true)
    var filterfs = {};
    setselectedFilterItem(filterfs)
    setSelectedDropdownYearItem({ Year: 'All' });
  }, [allData])


  const searchData2 = (value) => {
    if (value) {
      setSearchString(undefined);
    }
    else {
      setSearchString(value);
    }
    if (pageNo > 1) {
      onPageNoChange(1);
    }
  };

  const onYearDropdownChange = (data) => {
    if (data !== undefined && data !== null) {
      setSelectedDropdownYearItem(data)
    }
  };

  const applyFiltersNew = () => {

    setSelectedWorkorderCompany(selectedWorkorderCompanyTemp["Miworkordercompanymapping.workordercompanyname"])
    setSelectedWorkordercompanyid(selectedWorkorderCompanyTemp["Miworkordercompanymapping.workordercompanyid"])
    localStorage.setItem("serviceCenterSelectedItem", JSON.stringify(selectedWorkorderCompanyTemp))
  }


  const onPageNoChange = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setOffset(num);
    setPageNo(page);
  };

  const onDetailPageNoChange = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setDetailOffset(num);
    setDetailPageNo(page);
  };

  const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined,
    isResultShownFromApi: i.isResultShownFromApi ? i.isResultShownFromApi : false,
    chartType: i.chartType ? i.chartType : undefined
  });

  const onSCReportItemClick = (e, row, index) => {
    e.preventDefault();
    setShowBlurLoader(true);
    setReportDetailsData(undefined);

    setPopupURL({ url: `${process.env.REACT_APP_API_KEY}/ServiceCentre/GetMIWorkOrderReportDetailsByReportId?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&reportId=${row["ReportHistory.ReportId"]}`, type: "get", count: "" }); //vaibhav

    request(baseURL + `/ServiceCentre/GetMIWorkOrderReportSummaryByReportId?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&reportId=${row["ReportHistory.ReportId"]}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
      }
    )
      .then((res) => {
        setReportDetailsData(res?.result);
        setShowReportDetailModel(true);
        setShowBlurLoader(false);
      })
      .catch((err) => {
        setReportDetailsData(undefined);
        setShowBlurLoader(false);
        setShowReportDetailModel(false);
      })
  }

  const tempFunc = (obj) => {
    setNumberOfRecords(0)
    if (!obj) {
      setSearchData("");
    } else {
      setReportDetailsData(undefined);
    }

  }

  const applyfilter1 = () => {
    var filterfs = {};
    filterfs["ReportHistory.ReportPeriod"] = selectedDropdownYearItem.Year;
    setselectedFilterItem(filterfs)
    if (selectedDropdownYearItem.Year == "All") {
      cancelfilter1();
    }
  }

  const cancelfilter1 = () => {
    var filterfs = {};
    setselectedFilterItem(filterfs)
    setSelectedDropdownYearItem({ Year: 'All' });

  }


  const GenerateDigestPdf = async (reportId, reportName) => {
    setShowBlurLoader(true)
    await axios.get(`${process.env.REACT_APP_API_KEY}/ServiceCentre/GenerateDigestPDF?reportId=${reportId}&reportName=${reportName}&companyId=${contextData.companyId}&userId=${contextData.userId}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = reportName + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setShowBlurLoader(false)
      })
      .catch((err) => {
        setShowBlurLoader(false)
        console.error('Error generating PDF:', err);
      })
  };

  const createFilter = useMemo(
    () => (
      <>
        <Filters
          iconText={t("header.Icon_Tooltip_Filter")}
          applyFilters={applyfilter1}
          cancelFilter={cancelfilter1}
          showHeader={false}
          btnOneText={t("header.serviceCenterFilterText")}
          btnTwoText={t("header.serviceSencerFilter")}
          dataSource={[
            {
              name: "Year",
              data: dropdownYearList,
              selectedItem: selectedDropdownYearItem,
              isVisible: true,
              label: t("header.serviceCenterFilterLabel"),
              callback: onYearDropdownChange,
              displayText: "Year",
            }
          ]}
        ></Filters>
      </>
    ),
    [selectedDropdownYearItem, dropdownYearList]
  );


  const dashboardItem = (item) => (
    <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}>
        <Row className="">
          <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
            <div className="pe-4 d-inline-block pull-right form-filter-container">
              {
                filterShow ?
                  createFilter
                  :
                  <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                    <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                      <span className="icon-nav-box  ms-0 disabled"  >
                        <svg className="icon-nav">
                          <title>{t("header.AlertManageSubscribersConsentFilterText")}</title>
                          <use href={iconSprite + `#Filter`} />
                        </svg>
                      </span>
                    </div>
                  </div>
              }
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} className="text-end service-filter">
            <Search setsearchData={setSearchData} searchData={searchData} isAutoComplete={false}
              searchWaterMark={t("header.serviceCenterSearchPlaceHolder")} type={'client'}></Search>
          </Col>
        </Row>
        {/* Main Table */}
        <ChartRendererTable
          onSCReportItemClick={(e, row, index) => { onSCReportItemClick(e, row, index) }}
          GenerateDigestPdf={GenerateDigestPdf}
          searchText={searchString}
          minimumRows={10}
          showPagination={true}
          apiUrl={item.apiUrl}
          summaryBesideText={t("header.serviceCenterTotalReports")}
          showNumberOfRows={10}
          SearchText={searchData}
          setSearchText={setSearchData}
          FilterParams={selectedFilterItem}
          IsFilterByInclude={true}
          item={item}
          Exportdata={setAllData}
        />
      </DashboardItem>
    </div>
  );

  // const detailDashboardItem = (item) => (
  //   <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
  //     <DashboardItem
  //       key={item.id}
  //       itemId={item.id}
  //       title={item.name}
  //       enableOption={item.enableOption}>
  //       <ChartRenderer vizState={item.vizState} cardProperty={item.layout}
  //         setPageNo={setPageNo} pageNo={detailPageNo} onPageNoChange={onDetailPageNoChange}
  //         showPagination={item.pagination} offset={detailOffset} setOffset={setDetailOffset}
  //         minimumRows={TABLE_TOTAL_ROWS} />
  //     </DashboardItem>
  //   </div>
  // );



  const onClientDropdownChange = (data) => {
    if (pageNo > 1) {
      onPageNoChange(1);
    }
    setSelectedWorkorderCompanyTemp(data)

    setSearchData("")
    // setAutoLoad(false)
  };

  const renderClients = ({ resultSet, error, pivotConfig }) => {

    if (!resultSet) {
      return <Loader show={false} />;
    }
    // load first item

    if (resultSet !== null && resultSet.length) {
      localStorage.setItem("clientsData", JSON.stringify(resultSet));
      onClientDropdownChange(resultSet[0])
      setSelectedWorkorderCompany(resultSet[0]["Miworkordercompanymapping.workordercompanyname"])
      setAutoLoad(true)
    }


  };


  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.getConnectIdIntelligence")}</h2>
    </div>
  );

  const getReportPeriod = (val) => {
    return moment(val["Miworkorderreport.reportstartdate"]).format("MMM") + " " + moment(val["Miworkorderreport.reportstartdate"]).format("DD") + " " + moment(val["Miworkorderreport.reportstartdate"]).format("YYYY") + " - " + moment(val["Miworkorderreport.reportenddate"]).format("MMM") + " " + moment(val["Miworkorderreport.reportenddate"]).format("DD") + ", " + moment(val["Miworkorderreport.reportenddate"]).format("YYYY");
  }

  const cancelFilters = () => {
    onClientDropdownChange(JSON.parse(localStorage.getItem("serviceCenterSelectedItem")))
  }

  useEffect(() => {

    if (contextData.companyId > 0 && Object.keys(selectedWorkorderCompany).length == 0) {
      GetWOClientListDashboard();
    }
  }, [autoLoad, contextData.companyId]);

  const GetWOClientListDashboard = async () => {
    try {
      request(baseURL + `/ServiceCentre/GetWOClientListDashboard?CompanyId=${contextData.companyId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }).then((res) => {
          let resultData = [];
          if (res.result) {
            resultData = res.result.ResultData.map((e) => ({
              ["Miworkordercompanymapping.workordercompanyid"]: e.workordercompanyid,
              ["Miworkordercompanymapping.workordercompanyname"]: e.workordercompanyname
            }));
          }
          renderClients({ resultSet: resultData });
        })

    } catch (err) {
      console.error("Error", err)
    }
  }

  const commonHeaderChild = useMemo(() => <CommonHeader cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - clients', data: JSON.parse(localStorage.getItem("clientsData")), selectedItem: selectedWorkorderCompanyTemp, isVisible: true, label: t("header.serviceCenterFilterLable"), callback: onClientDropdownChange, displayText: "Miworkordercompanymapping.workordercompanyname" }
  ]} title={t("header.ServiceCenterTitle")} showDate={false} applyFilters={applyFiltersNew} />, [selectedWorkorderCompanyTemp, autoLoad])

  return (
    <>
      {reportDetailsData &&
        <ModalComponent
          title={reportDetailsData?.WorkorderReportName}
          showCloseButton={true}
          open={showReportDetailModel}
          handleClose={() => { setSearchData(""); setTimeout(() => { setSearchData(searchData) }, 50); setShowReportDetailModel(false); setSearchDataPopup(""); tempFunc(); }}
        >
          <Row>
            <Col col={6} className='text-start'><span>{reportDetailsData?.WorkorderCompanyName}</span></Col>
            <Col col={6} className='text-end'><span>{moment(reportDetailsData?.StartDate).format("MMM DD,YYYY")} - {moment(reportDetailsData?.EndDate).format("MMM DD,YYYY")}</span></Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md={12}>
              <span className='fw-bold'>
                {t("header.serviceCenterMessage1")}
              </span>
              <span className='fw-bolder'>
                {((reportDetailsData?.TotalAdditonalHours + reportDetailsData?.TotalMaintenanceHours) - reportDetailsData?.TotalContractBillableHours).toFixed(2)} {t("header.serviceCenterSummaryLabe5")} ({t("header.serviceCenterSummaryLabe6")} {moment(reportDetailsData?.TimeStamp).format("MMM DD,YYYY hh:mm A")})
              </span>
            </Col>
            <Col md={12}><span className='fw-bold'>{t("header.serviceCenterMessage2")}</span> <span className='fw-bolder'>{reportDetailsData?.TotalBillableHours.toFixed(2)} {t("header.serviceCenterSummaryLabe5")}</span></Col>
          </Row>
          <Row className='mt-2'>
            <Col className="service-centre-report-detail-modal">
              <Row className="mb-2">
                <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
                  <div className="d-inline-block download-excel">
                    <span className={numberOfRecords > 0 ? 'icon-nav-box hide-mobile' : 'icon-nav-box disabled hide-mobile'}
                      onClick={(e) => { GenerateDigestPdf(reportDetailsData.ReportId, reportDetailsData.WorkorderReportName) }}
                    >
                      <svg className="icon-nav">
                        <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                        <use href={iconSprite + `#Download`} />
                      </svg>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} className="text-end service-filter">
                  <Search
                    setsearchData={setSearchDataPopup}
                    searchWaterMark={t("header.Servicecenter_Popup_Search_Placeholder")}
                    searchData={searchDataPopup}
                    isAutoComplete={false}
                    type={'client'}
                  ></Search>
                </Col>
              </Row>
              {/* Popup Table */}
              {
                <ChartRendererTable
                  minimumRows={10}
                  totalRecords={numberOfRecords}
                  setNumberOfRecords={setNumberOfRecords}
                  showPagination={true}
                  showCount={false}
                  apiUrl={popupURL}
                  // apiUrl={{ url: `${process.env.REACT_APP_API_KEY}/ServiceCentre/GetMIWorkOrderReportDetailsByReportId?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&reportId=${reportDetailsData.ReportId}`, type: "get", count: "" }}
                  showNumberOfRows={10}
                  setSearchText={setSearchDataPopup}
                  SearchText={searchDataPopup}
                />
              }
            </Col>
          </Row>
        </ModalComponent>}

      {showBlurLoader ? <div id='blurdiv' className="blurbackground service-center-modal-backdrop">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div> : ""}
      {/* {showModal &&
        <ServiceCenterReportDetail detailPageNo={detailPageNo} detailSearchData={detailSearchData} clientName={selectedWorkorderCompany["Miworkordercompanymapping.workordercompanyname"]}
          setShowModal={setShowModal} reportTitle={selectedItem["Miworkorderreport.workorderreportname"]}
          remainingServiceHours={selectedItem["Miworkorderreport.RemainingHours"]} createddt={selectedItem["Miworkorderreport.createddt"]}
          reportPeriod={getReportPeriod(selectedItem)} billableHours={selectedItem["Miworkorderreport.totalbillablehours"]}>
          {reportDetailData !== null && reportDetailData.map(deserializeItem).map(detailDashboardItem)}
        </ServiceCenterReportDetail>
      } */}
      <div className="service-hour">
        <Row>
          <Col sm={12}>
            {commonHeaderChild}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={12}>
            <div className="bg-white shadow-soft border rounded border-light p-4 ms-3">
              <YourInvestisTeam setShowBlurLoader={setShowBlurLoader} columns={3}></YourInvestisTeam>
            </div>
          </Col>
        </Row>
        <Row>
          {/* summary section */}
          <Col>
            <Row>
              <Col lg={12}>
                <div className="ms-3">
                  {showSuccess ? <div className="fade modal-backdrop show"></div> : ""}
                  <Alert
                    className="toast-custom"
                    variant="success"
                    show={showSuccess}>

                    <div className="d-flex justify-content-between mb-0">
                      <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {t("header.RequestHoursSaveMessage")}
                      </div>
                      <Button variant="close" size="xs" onClick={() => onCloseAlert("success")} />
                    </div>
                  </Alert>
                  <Alert
                    variant="danger"
                    show={showError}>
                    <div className="d-flex justify-content-between mb-0">
                      <div>
                        <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                        {t("header.RequestHoursSaveErrorMessage")}
                      </div>
                      <Button variant="close" size="xs" onClick={() => onCloseAlert("success")} />
                    </div>
                  </Alert>
                </div>
              </Col>
            </Row>
            <ServiceCenterSummaryComponent setShowBlurLoader={setShowBlurLoader} workordercompanyid={selectedWorkordercompanyid}></ServiceCenterSummaryComponent>
          </Col>
        </Row>
        <Row className="mb-3 mt-2">
          <Col lg={12}>
            <ServiceCenterRequestMoreHoursComponent setShowBlurLoader={setShowBlurLoader} setshowError={setshowError} setshowSuccess={setshowSuccess} workorderCompany={selectedWorkorderCompany} username={localStorage.username} useremail={localStorage.useremail}></ServiceCenterRequestMoreHoursComponent>
          </Col>
        </Row>
        <Row className="mb-3">
          {!reportData || reportData.length > 0 ? (
            <Col xs={12}>
              <Row>
                <Col xs={12} className="ps-0">
                  <ServiceCenterComponent>
                    {reportData && reportData.map(deserializeItem).map(dashboardItem)}
                  </ServiceCenterComponent>
                </Col>
              </Row>
            </Col>
          ) : (<Empty />)}
        </Row>
      </div></>

  )
}

export default ServiceCentrePages