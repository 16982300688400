import React, { useEffect, useState, useContext, useMemo } from 'react';
import AlertStats from '../components/AlertStats';
import { alertStats, apiChartsAlertService, alertStatsTable } from "../stubs/ComponentJson/alerts";
import DashboardItem from '../components/DashboardItem';
import { Image, Dropdown, ButtonGroup, Alert, Row, Col, Button, } from '@themesberg/react-bootstrap'
import AppContext from '../components/AppContext';
import moment from "moment-timezone";
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import ApiChartRenderer from '../components/ApiChartRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { convertBase64 } from "../utils/common";
import { useTranslation } from 'react-i18next';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "../components/Search";
import Filters from "../components/Filters";
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    isResultShownFromApi: i.isResultShownFromApi ? true : false,
    chartType: i.chartType ? i.chartType : undefined,
    isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false,
    isLegendShown: i.isLegendShown ? i.isLegendShown : false,
    apiUrl: i.apiUrl ? i.apiUrl : undefined
});

export const AlertStatsPage = () => {
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [alertStatsData, setAlertStatsData] = useState([]);
    const [alertStatsDataTable, setAlertStatsDataTable] = useState([]);
    const [selectedOtpclientid, setSelectedOtpclientid] = useState({});
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [startDate, setStartDate] = useState(moment().add(-30, 'days'));
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [blurLoader, setBlurLoader] = useState(false);
    const [getTotalResults, setGetTotalResults] = useState(0);
    const [alertVisibleMessage, setAlertVisibleMessage] = useState(null);
    const [searchData, setsearchData] = useState("");
    const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
    const [headerFilterDataPopup, setheaderFilterDataPopup] = useState(undefined);
    const [alertTypeFilter, setAlertTypeFilter] = useState([{ label: "Total Count" }, { label: "Unique Count" }]);
    const [alertTypeFilterSelectedItem, setalertTypeFilterSelectedItem] = useState({ label: "Total Count" });
    const [flag, setFlag] = useState(false);
    const [categories, setCategories] = useState({});
    const [selectedCategoryId, setSelectedCategoryId] = useState({ CategoryId: null, CategoryName: "All Categories" });
    const [showAlertStatisticsModel, setShowAlertStatisticsModel] = useState(false);
    const [showEmailAlertModel, setShowEmailAlertModel] = useState(false);
    const [messageDetails, setMessageDetails] = useState(null);

    useEffect(() => {
        const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
        const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
        const websiteId = contextData.websiteId;
        const userId = contextData.userId;
        const companyId = contextData.companyId
        let dta;
        let dtaForTable;
        let categoryId = 0
        const otpClientId = 29;//selectedOtpclientid && selectedOtpclientid["ClietConfiguration.otpclientid"] === undefined ? 29 : selectedOtpclientid["ClietConfiguration.otpclientid"];
        const culture = localStorage.getItem("i18nextLng");

        if (otpClientId && websiteId > 0 && contextData.selectedTab == "Alerts") {
            dta = alertStats(otpClientId, selectedstartdate, selectedenddate, offset, websiteId, companyId, userId, categoryId, "", culture);
            dtaForTable = alertStatsTable(otpClientId, selectedstartdate, selectedenddate, offset, websiteId, companyId, userId, "Total Count", categoryId, "")

        }
        else {
            dta = [];
            dtaForTable = [];
        }

        setAlertStatsData(dta);
        setAlertStatsDataTable(dtaForTable);
        setsearchData("")
        setalertTypeFilterSelectedItem({ label: "Total Count" })
    }, [contextData.startDate, contextData.selectedTab, contextData.endDate, contextData.websiteId, contextData.companyId]);

    useEffect(() => {
        const websiteId = contextData.websiteId;
        //for category dropdown
        if (contextData.selectedTab == "Alerts") {
            request(`${process.env.REACT_APP_API_KEY}/Intelligence/GetCASCategoriesByClient?WebsiteId=${websiteId}&companyid=${contextData.companyId}&userid=${contextData.userId}`, METHODS.GET, {},
                { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
                .then((response) => {
                    if (response != null) {
                        let data = [{ CategoryId: 0, CategoryName: "All Categories" }, ...response?.result]

                        setCategories(data);
                    }
                })
                .catch(() => {
                })
        }
        setsearchData("")
        setalertTypeFilterSelectedItem({ label: "Total Count" })
        setSelectedCategoryId({ CategoryId: null, CategoryName: "All Categories" })
        contextData.changeAlertCategory({ CategoryId: null, CategoryName: "All Categories" })
    }, [contextData.websiteId])

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    }

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setBlurLoader(true)
        const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
        let config = {
            headers: {
                'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                'content-type': "application/json"
            },
            params: {
                encodeStr: convertBase64(JSON.stringify({
                    WebsiteId: contextData.websiteId,
                    startDate: moment(contextData.startDate).format("YYYY-MM-DD"),
                    endDate: moment(contextData.endDate).format("YYYY-MM-DD"),
                    categoryId: contextData.alertCategoryId?.CategoryId ? contextData.alertCategoryId?.CategoryId : 0,
                    companyid: contextData.companyId,
                    userid: contextData.userId,
                    companyName: selectedComapnyData.companyName,
                    webSiteName: selectedOtpclientid["WebsiteList.WebsiteName"],
                    categoryName: !contextData.alertCategoryId?.CategoryName ? "All Categories" : contextData.alertCategoryId?.CategoryName,
                    // categoryName: "All_Categories",
                    messageType: alertTypeFilterSelectedItem.label,
                    alertType: !contextData.alertCategoryId?.CategoryName || contextData.alertCategoryId?.CategoryName == "All Categories" ? "" : contextData.alertCategoryId?.CategoryName == "Adhoc" ? "adhoc" : "alert",
                    culture: localStorage.getItem('i18nextLng'),
                    filter: alertTypeFilterSelectedItem.label
                }))
            }
        }

        const tempVar = await axios.get(
            `${process.env.REACT_APP_API_KEY}/Intelligence/ExportCASMessageDetailsUsingEPPlus`,
            config
        );
        if (tempVar !== undefined && tempVar !== null) {
            setBlurLoader(false)
            let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
            let a = document.createElement("a");
            a.href = fetchDataModified;
            a.download = 'Alert_Statistics_All_Categories' + '.xlsx';
            a.click();
        }
    };

    const downloadPDF = async () => {
        setBlurLoader(true);
        let fileName = "Alert_Statistics.pdf";
        let obj = {
            Title: document.getElementById("websiteWebsiteProfile").innerHTML,
            SubTitle: document.getElementById("dateRange").innerHTML,
            PageName: "Alert Statistics",
            CountTitle: "<span class='total-record-count'>{{count}}</span> Alerts",
            Filters: "",
            ClientName: JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyName"],
            Url: alertStatsDataTable.map(deserializeItem)?.find(x => x.id === "8")?.apiUrl?.url,
            Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
            Count: 0,
            FilterContent: "",
            TableHeaders: []
        }

        await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                }
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisibleMessage(t("header.PdfDeliveredToMailMessage"));
                        setTimeout(() => {
                            setAlertVisibleMessage(null);
                        }, 3000);
                    }
                    setBlurLoader(false);
                }
                else {
                    setBlurLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const handleAlertPopup = (type, messageDetails) => {
        setMessageDetails(messageDetails);
        if (type === "viewDeliveredEmailAlert") {
            setShowEmailAlertModel(true);
        }
        else if (type === "viewAlertStatistics") {
            setShowAlertStatisticsModel(true);
        }
    }

    const createFilter = useMemo(
        () => (
            <>
                <Filters
                    iconText={t("header.Icon_Tooltip_Filter_Count")}
                    applyFilters={applyfilter1}
                    showHeader={false}
                    btnOneText={t("header.Filter_Btn_Text")}
                    dataSource={[
                        {
                            name: "Alert Type",
                            data: alertTypeFilter,
                            selectedItem: alertTypeFilterSelectedItem,
                            isVisible: true,
                            callback: onAlertTypeDropDownChange,
                            displayText: "label",
                        }
                    ]}
                ></Filters>
            </>
        ),
        [alertTypeFilter, alertTypeFilterSelectedItem]
    );

    function applyfilter1() {
        setFlag(!flag)
        const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
        const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
        const websiteId = contextData.websiteId;
        const userId = contextData.userId;
        const companyId = contextData.companyId;
        const categoryId = contextData.alertCategoryId?.CategoryId ? contextData.alertCategoryId?.CategoryId : 0
        const alertCategoryName = contextData.alertCategoryId?.CategoryName == undefined || contextData.alertCategoryId?.CategoryName == "All Categories" ? "" : contextData.alertCategoryId?.CategoryName == "Adhoc" ? "adhoc" : "alert"
        const otpClientId = 29;
        let dta = alertStatsTable(otpClientId, selectedstartdate, selectedenddate, offset, websiteId, companyId, userId, alertTypeFilterSelectedItem.label, categoryId, alertCategoryName)
        setAlertStatsDataTable(dta)
    }

    function onAlertTypeDropDownChange(data) {
        if (data !== undefined && data !== null) {
            setalertTypeFilterSelectedItem(data)
        }
    }

    const onDropdownChange = (data) => {
        contextData.changeAlertCategory(data)
        setSelectedCategoryId(data)
        const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
        const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
        const websiteId = contextData.websiteId;
        const userId = contextData.userId;
        const companyId = contextData.companyId
        const categoryId = data.CategoryId

        let dta;
        let dtaForTable;
        const otpClientId = 29;//selectedOtpclientid && selectedOtpclientid["ClietConfiguration.otpclientid"] === undefined ? 29 : selectedOtpclientid["ClietConfiguration.otpclientid"];
        const culture = localStorage.getItem("i18nextLng");

        let alertType = data.CategoryName == "All Categories" ? "" : data.CategoryName == "Adhoc" ? "adhoc" : "alert";

        if (otpClientId && websiteId > 0) {
            dta = alertStats(otpClientId, selectedstartdate, selectedenddate, offset, websiteId, companyId, userId, categoryId, alertType, culture);
            dtaForTable = alertStatsTable(otpClientId, selectedstartdate, selectedenddate, offset, websiteId, companyId, userId, "Total Count", categoryId, alertType)
            setAlertStatsData(dta);
            setAlertStatsDataTable(dtaForTable);
        }
        else {
            dta = null;
            dtaForTable = null;
        }
        setsearchData("")
        setalertTypeFilterSelectedItem({ label: "Total Count" })
    };

    const dashboardItem = (item) => (
        <>
            <Col md={item.id == "1" ? 12 : 6} className='col-spacing-bottom'>
                <DashboardItem
                    key={item.id}
                    itemId={item.id}
                    title={item.name}
                    summary={item.summary}
                    chartType={item.vizState.chartType}
                    pageName={"AlertStatsPage"}
                    summaryTooltip={t(item.tooltip)}
                >
                    {item.chartType && item.chartType === "multiline" ?
                        <>
                            <Row>
                                <Col>
                                    <h5 className="ms-3 mt-4">{t("header.alertCategoryTitle")}</h5>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col xs={11} sm={4} className="mb-1">
                                    {<DropdownWithIconComponent
                                        dropdownData={categories}
                                        arrayValue="CategoryName"
                                        selectedDropdown={selectedCategoryId}
                                        onChange={onDropdownChange}
                                        showLabel={false}
                                        showIconInDropdownItem={false}
                                        customClassName="full-width text-overflow ms-3 mt-2"
                                        isDropdownDisabled={false}
                                    />}
                                </Col>
                            </Row>
                        </> : <></>}
                    {(item.isResultShownFromApi && item.chartType && item.chartType !== "table") ?
                        <>
                            <div className={'mb-3 alert-statistis-chart-container-' + item.id}>
                                <ApiChartRenderer setAlertVisibleMessage={setAlertVisibleMessage} isLegendShown={item.isLegendShown} isSummeryShown={item.isSummeryShown} services={apiChartsAlertService} chartType={item.chartType} legendPercentage={item.legendPercentage} itemId={item.id} vizState={item.vizState} cardProperty={Object.keys(item.layout).length > 0 ? item.layout : item.layout}
                                    pageNo={pageNo} onPageNoChange={onPageNoChange}
                                    legends={item.legends} showOthers={item.showOthers ? item.showOthers : false} showOthersColume={item.showOthersColume !== "" ? item.showOthersColume : ""} showOthersColumeName={item.showOthersColumeName !== "" ? item.showOthersColumeName : ""} cardName={item.cardName}
                                />
                            </div>
                        </>
                        : <></>
                    }
                    {
                        item.apiUrl !== undefined &&
                        <>
                            <ChartRendererTable
                                handleEditCancel={(e, row, rowkey, type, messageDetails) => { handleAlertPopup(type, messageDetails) }}
                                minimumRows={10}
                                summaryBesideText={item.id == 8 ? t("header.AlertStatsAlertsCountText") : ""}
                                showPagination={item.id == 8 ? true : false}
                                apiUrl={item.apiUrl}
                                showNumberOfRows={item.id == 8 ? 10 : 4}
                                setGetTotalResults={(value) => setGetTotalResults(value)}
                                showOthersColume={item.showOthersColume !== "" ? item.showOthersColume : ""}
                                SearchText={searchData}
                                pageName={"alertanalytics"}
                            />
                        </>
                    }
                </DashboardItem>
            </Col>
        </>
    );

    const dashboardItem2 = (item) => (
        <Col md={12}>
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={item.name}
                summary={item.summary}
                chartType={item.vizState.chartType}
            >
                {
                    item.apiUrl !== undefined &&
                    <>
                        {
                            item.id == "8" &&
                            <>
                                <Row className="">
                                    <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
                                        <div className="d-inline-block pull-right form-filter-container">
                                            {getTotalResults > 0 ?
                                                createFilter
                                                :
                                                <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                                                    <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                                        <span className="icon-nav-box  ms-0 disabled"  >
                                                            <svg className="icon-nav">
                                                                <title>{t("header.Icon_Tooltip_Filter")}</title>
                                                                <use href={iconSprite + `#Filter`} />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="pe-4 d-inline-block pull-right form-filter-container">
                                            <span className={getTotalResults > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                <Dropdown title={t("header.iconTooltiop_download", "Download")} as={ButtonGroup}>
                                                    <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                        <svg className="icon-nav">
                                                            <title>{t("header.Icon_Tooltip_Download")}</title>
                                                            <use href={iconSprite + `#Download`} />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {setDowloadDropdownMenu()}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col xs={12} className="text-end service-filter">
                                        <Search
                                            setsearchData={setsearchData}
                                            searchData={searchData}
                                            searchWaterMark={t("header.searchAlertTitle")}
                                            isAutoComplete={true}
                                            setAutoFillForCompany={setAutoFillForCompanyPopup}
                                            showAutoFillForCompany={showAutoFillForCompanyPopup}
                                            data={headerFilterDataPopup}
                                            type={"client"}
                                        ></Search>
                                    </Col>
                                </Row>
                            </>
                        }

                        <ChartRendererTable
                            handleEditCancel={(e, row, rowkey, type, messageDetails) => { handleAlertPopup(type, messageDetails) }}
                            minimumRows={10}
                            summaryTooltip={t(item.tooltip)}
                            summaryBesideText={item.id == 8 ? (contextData.alertCategoryId.CategoryName === "All Categories" ? t("header.All") : contextData.alertCategoryId.CategoryName) + " " + t("header.AlertStatsAlertsCountText") : ""}
                            showPagination={item.id == 8 ? true : false}
                            apiUrl={item.apiUrl}
                            showNumberOfRows={item.id == 8 ? 10 : 4}
                            setGetTotalResults={(value) => setGetTotalResults(value)}
                            showOthersColume={item.showOthersColume !== "" ? item.showOthersColume : ""}
                            SearchText={searchData}
                            setheaderFilterData={setheaderFilterDataPopup}
                            flag={flag}
                        />
                    </>
                }
            </DashboardItem>
        </Col>
    );

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            <h2>{t("header.noChartsonDashboard")}</h2>
        </div>
    );

    return alertStatsData && alertStatsData.length > 0 ? (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {alertVisibleMessage && (<div className="fade modal-backdrop show"></div>)}
            <Alert
                className="toast-custom"
                variant="success"
                show={alertVisibleMessage}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {alertVisibleMessage}
                    </div>
                    <Button variant="close" size="xs" onClick={() => setAlertVisibleMessage(null)} />
                </div>
            </Alert>
            <AlertStats
                dashboardItems={alertStatsData}
                selectedWebsiteId={selectedWebsiteId}
                selectedOtpclientid={selectedOtpclientid}
                setSelectedWebsiteId={setSelectedWebsiteId}
                setSelectedOtpclientid={setSelectedOtpclientid}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setPageNo={setPageNo}
                setOffset={setOffset}
                showAlertStatisticsModel={showAlertStatisticsModel}
                setShowAlertStatisticsModel={setShowAlertStatisticsModel}
                showEmailAlertModel={showEmailAlertModel}
                setShowEmailAlertModel={setShowEmailAlertModel}
                messageDetails={messageDetails}
            >
                {alertStatsData !== null && alertStatsData.map(deserializeItem).map(dashboardItem)}
                {alertStatsDataTable !== null && alertStatsDataTable.map(deserializeItem).map(dashboardItem2)}
            </AlertStats>
        </>
    ) : (
        <div id="alertStatistics" >
            <h1 className='text-center'>connect.ID Intelligence</h1>
        </div>
    );
};


export default AlertStatsPage;