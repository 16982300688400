import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger, Button, Alert, Image } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableComponent from "../components/Table";
import { useTranslation } from 'react-i18next';
import {
  tbRowWithCheckBox,
  tbRowWithToggle,
  checkArrayElementsAreSame,
  TABLE_TOTAL_ROWS,
  formatCount
} from "../AppConfig";
import AppContext from "../components/AppContext";
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ERROR_TIMEOUT } from "../AppConfig"
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from "../utils/crypto";

const ChartRendererTable = ({
  handleEditCancel,
  setIsMultiDeleteDisabled,
  onEditEvent,
  setTableDataForDelete,
  setCheckDocument,
  checkedDocument,
  allDocumentChecked,
  setAllDocumentChecked,
  onDocumentDownload,
  onDocumentEdit,
  onDeleteDocument,
  onSCReportItemClick,
  SearchText,
  cardProperty,
  items,
  setItems,
  onItemChange,
  pageNo,
  setPageNo,
  onPageNoChange,
  showSearchTextBox,
  searchText,
  setSearchText,
  resultSet,
  handlePlusButtonClick,
  onSearchBoxChange,
  legends,
  totalRecords,
  showPagination,
  deleteSubscriber,
  editSubscriber,
  showPaginationSummary,
  offset,
  setOffset,
  isRefresh,
  setIsRefresh,
  setd3,
  minimumRows,
  setShowModal,
  setSelectedItem,
  cardName,
  isSubscribe,
  title,
  downloadExcel,
  showPlusModal,
  setShowPlusModal,
  apiUrl,
  setheaderFilterData,
  setNumberOfRecords,
  showNumberOfRows,
  summaryTooltip,
  showCount,
  summaryBesideText,
  summaryPriorTextValue,
  openPopupEvent,
  showTotalCount,
  setGetTotalResults,
  Exportdata,
  FilterParams,
  showOthersColume,
  item,
  GenerateDigestPdf,
  loadedCardId,
  IsFilterByInclude,
  flag,
  showCardLoader
}) => {
  if (showNumberOfRows > 0) {
    minimumRows = showNumberOfRows;
  }

  let widjetName = "Watchlistdetail";
  let tableCaption = "";
  const [activepagedata, setActivePageData] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiResponseMaster, setApiResponseMaster] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [currentpageno, setCurrentPageNo] = useState(1);
  const [t, i18n] = useTranslation();
  const [summaryCount, setSummaryCount] = useState("");
  const [summaryBesideTextValue, setSummaryBesideTextValue] = useState("");
  const [tooltipText, setTooltipText] = useState(summaryTooltip);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [apiResponseBackup, setApiResponseBackup] = useState({});
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  useEffect(() => {
    if (setGetTotalResults !== undefined) {
      setGetTotalResults(summaryCount);
    }
  }, [summaryCount]);

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => { setAutoRefresh(!autoRefresh) }, 10000);
    }
  }, [isRefresh, autoRefresh]);

  useEffect(() => {
    setPageNumber(localStorage.getItem("pageNo"))
  }, [localStorage.getItem("pageNo")]);

  useEffect(() => {
    if (resultSet !== undefined) {
      let resultobj = resultSet;
      if (resultobj.data !== undefined) {
        let filteredobj
        if (pageNumber > 1) {
          filteredobj = resultobj.data.slice((((pageNumber - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS))), (((pageNumber - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS))) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS));
        } else {
          filteredobj = resultobj.data.slice(0, showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS);
        }
        setActivePageData(filteredobj);
        setApiResponse(resultobj);
        if (resultobj.data.length !== undefined) {
          setSummaryCount(resultobj.data.length);
        }
      }
    }
  }, [resultSet]);

  useEffect(() => {
    setLoader(true)
  }, [currentpageno, flag]);

  useEffect(() => {
    setCurrentPageNo(1)
    setApiResponse(null);
    setLoader(true)
    localStorage.setItem("pageNo", 1);
    setAutoRefresh(!autoRefresh)
  }, [searchText]);

  useEffect(() => {
    setLoader(true);
    setTooltipText(summaryTooltip)
    setSummaryBesideTextValue(summaryBesideText);
    if (apiUrl) {
      if ((!isRefresh)) {
        setApiResponse(null);
        setCurrentPageNo(1);
      }
      request(apiUrl.url, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
        .then((response) => {
          setApiResponseBackup(response)
          let resultobj = response;
          if (resultobj.data.length && item?.id !== 0 && loadedCardId) {
            loadedCardId(parseInt(item.id))
          }
          let filteredobj = {}
          // for autoRefresh
          if (pageNumber > 0) {
            filteredobj = resultobj.data.slice(((pageNumber - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), (((pageNumber - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)));
            if (showOthersColume !== undefined && showOthersColume.showOthers) {
              let totalLenght = resultobj.data?.length;
              //below given condition is added so that, if there are less than 4 records, in that case remaining country/domains rows is not required *** for alert analytics page
              if (totalLenght > 4) {
                let newRow = {}
                let totalSumRows = resultobj.data.slice(showNumberOfRows, totalLenght);
                newRow[showOthersColume.showOthersColumeName[0]] = t("header." + showOthersColume.showOthersColumeName[1])
                filteredobj.push(newRow);
                for (let item of showOthersColume.showOthersColume) {
                  newRow[item] = totalSumRows.reduce((a, b) => a + b[item], 0)
                }
              }
            }
          } else {
            filteredobj = resultobj.data.slice(0, (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS));
          }
          setActivePageData(filteredobj);
          setApiResponse(resultobj);
          setApiResponseMaster(resultobj.data);

          if (resultobj.data.length !== undefined && !showTotalCount) {
            setSummaryCount(resultobj.data.length);
          }
          else if (resultobj.data.length !== undefined && showTotalCount) {
            setSummaryCount(resultobj.count);
          }

          if (Exportdata !== undefined) {
            resultobj ? Exportdata(resultobj) : Exportdata(null);
          }

          if (setheaderFilterData !== undefined) {
            setheaderFilterData(resultobj.filterdata)
          }
          if (resultobj.data.count !== undefined) {
            apiUrl.count = resultobj.count;
          }
          setLoader(false)

          SearchText && setIsFilterChange(!isFilterChange);
        })
        .catch(() => {
        })
    }
  }, [apiUrl]);

  useEffect(() => {
    if (apiResponse !== null && apiResponse?.count > 0 && SearchText) {
      var result = apiResponse.data;
      let filteredobj;
      if (apiResponse.filterColumns !== undefined) {
        const filtercolumns = apiResponse.filterColumns;
        var filterfs = {};

        for (let i = 0; i < filtercolumns.length; i++) {
          filterfs[filtercolumns[i]] = SearchText;
        }

        result = result.filter(function (item) {
          for (var key in filterfs) {
            if (item[key]?.toLowerCase() == filterfs[key]?.toLowerCase()) {
              return true;
            }
            else if (item[key]?.toLowerCase().includes(filterfs[key]?.toLowerCase())) {
              return true;
            }
          }
          return false;
        });
        filteredobj = result;

      }
      else if (apiResponse.filter === "contains") {
        filteredobj = apiResponse?.data?.filter(item => item.filterybykey.toLowerCase().indexOf(SearchText.toLowerCase()) > -1);
      }
      else {
        if (SearchText) {
          filteredobj = apiResponse?.data?.filter(item => item.filterybykey === SearchText);
        } else {
          filteredobj = result;
        }
      }

      let filtervalueexists = 0;
      let filterfs2 = {};
      let columns = [];
      for (const obj in FilterParams) {
        if (FilterParams[obj] != "All" && FilterParams[obj] != 0) {
          filtervalueexists = 1;
          filterfs2[`${obj}`] = FilterParams[obj]
          columns.push(obj)
        }
      }

      if (filtervalueexists == 1) {
        if (columns.length > 0) {
          if (!IsFilterByInclude) {
            filteredobj = filteredobj.filter(function (item) {
              if (item[columns[0]] == filterfs2[columns[0]]) {
                return true;
              }
              return false;
            });
          } else {
            filteredobj = filteredobj.filter(function (item) {
              if (item[columns[0]].includes(filterfs2[columns[0]])) {
                return true;
              }
              return false;
            });
          }
        }
        if (columns.length > 1) {
          filteredobj = filteredobj.filter(function (item) {
            if (item[columns[1]] == filterfs2[columns[1]]) {
              return true;
            }
            return false;
          });
        }
      }

      SearchText && setSummaryCount(filteredobj ? filteredobj.length : 0); //this is necessary
      setActivePageData(filteredobj.slice(0, (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)));
      setNumberOfRecords && setNumberOfRecords(filteredobj ? filteredobj.length : 0)
      setCurrentPageNo(1);
    }
    else if (apiResponse !== null && apiResponse?.count > 0) {

      let filteredobj = apiResponse.data;

      let filtervalueexists = 0;
      let filterfs2 = {};
      let columns = [];
      for (const obj in FilterParams) {
        if (FilterParams[obj] != "All" && FilterParams[obj] != 0) {
          filtervalueexists = 1;
          filterfs2[`${obj}`] = FilterParams[obj]
          columns.push(obj)
        }
      }

      if (filtervalueexists == 1) {
        if (columns.length > 0) {
          if (!IsFilterByInclude) {
            filteredobj = filteredobj.filter(function (item) {
              if (item[columns[0]] == filterfs2[columns[0]]) {
                return true;
              }
              return false;
            });
          } else {
            filteredobj = filteredobj.filter(function (item) {
              if (item[columns[0]].includes(filterfs2[columns[0]])) {
                return true;
              }
              return false;
            });
          }
        }
        if (columns.length > 1) {
          filteredobj = filteredobj.filter(function (item) {
            if (item[columns[1]] == filterfs2[columns[1]]) {
              return true;
            }
            return false;
          });
        }
      }

      setSummaryCount(filteredobj.length);
      setActivePageData(filteredobj.slice(0, (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)));
      setNumberOfRecords && setNumberOfRecords(filteredobj.length)
      setCurrentPageNo(1);
    }
  }, [SearchText, apiResponse, FilterParams, isFilterChange]);

  const onEventchange = (obj, index, type, msg) => {
    if (msg.includes("-Successmsg-")) {
      setSuccessMessage(msg.replace("-Successmsg-", ""));
    }
    else if (msg.includes("-Errormsg-")) {
      setErrorMessage(msg.replace("-Errormsg-", ""));
    }

    setTimeout(() => {
      if (msg.includes("-Successmsg-")) {
        setSuccessMessage(undefined);
      }
      else if (msg.includes("-Errormsg-")) {
        setErrorMessage(undefined);
      }
      const mainindex = ((currentpageno - 1) * TABLE_TOTAL_ROWS) + index;
      if (type === "Modify") {
        let newobj = apiResponse.data.map((c, i) => {
          if (i === mainindex) {
            return obj;
          } else {
            return c;
          }
        });
        let filteredobj = newobj.slice(((currentpageno - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), ((currentpageno - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS));

        setActivePageData(filteredobj);

        setApiResponse((prevState) => ({
          ...prevState,
          data: newobj,
        }));

        if (newobj.length === 0) {
          setIsRefresh(true);
        }
      }
      else if (type === "Remove") {

        apiResponse.data.splice(index, 1);

        setSummaryCount(apiResponse.data.length);
        setApiResponse(apiResponse);

        let filteredobj = apiResponse.data.slice(((currentpageno - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), ((currentpageno - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS));
        if (filteredobj.length === 0 && currentpageno > 1) {
          setCurrentPageNo(parseInt(currentpageno - 1));
          filteredobj = apiResponse.data.slice(((currentpageno - 2) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), ((currentpageno - 2) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS));
        }
        setActivePageData(filteredobj);
        if (apiResponse.data.length === 0) {
          setIsRefresh(true);
        }
      }
    }, ERROR_TIMEOUT);
  }

  const onCloseErrorMessage = () => {
    setErrorMessage(undefined)
  };

  const onCloseSuccessMessage = () => {
    setSuccessMessage(undefined)
  };

  const onSortingChange = (key, order, item) => {
    if (item.type === "number") {
      if (order === "asc") {
        apiResponse.data.sort((a, b) => b[key] - a[key])
      } else {
        apiResponse.data.sort((a, b) => a[key] - b[key])
      }
    }
    else if (item.type === "string") {
      if (order === "asc") {
        apiResponse.data.sort((a, b) => b[key] !== null ? b[key].trim().localeCompare(a[key].trim()) : '')
      } else {
        apiResponse.data.sort((a, b) => a[key] !== null ? a[key].trim().localeCompare(b[key].trim()) : '')
      }
    }
    else if (item.type === "time") {
      if (order === "asc") {
        apiResponse.data.sort((a, b) => new Date(b[key]) - new Date(a[key]))
      } else {
        apiResponse.data.sort((a, b) => new Date(a[key]) - new Date(b[key]))
      }
    }
    else if (item.type === "hours") {
      if (order === "asc") {
        apiResponse.data.sort((a, b) => b[key].localeCompare(a[key]))
      } else {
        apiResponse.data.sort((a, b) => a[key].localeCompare(b[key]))
      }
    } else if (item.type === "numberseconds") {
      if (order === "asc") {
        apiResponse.data.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]))
      } else {
        apiResponse.data.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]))
      }
    }
    onPageNoChangeChart(1);
  }

  const onPageNoChangeChart = (number) => {
    if ((SearchText === "" || SearchText === t("header.WONotificationcompanyValidation")) && (FilterParams === undefined || Object.keys(FilterParams).length === 0)) {
      let filteredobj = apiResponse.data.slice(((number - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), ((number - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS));
      setCurrentPageNo(number);
      setActivePageData(filteredobj);
    }
    else if (FilterParams !== undefined && Object.keys(FilterParams).length > 0) {
      let filtervalueexists = 0;
      let filterfs = {};
      let columns = [];
      for (const obj in FilterParams) {
        if (FilterParams[obj] != "All" && FilterParams[obj] != 0) {
          filtervalueexists = 1;
          filterfs[`${obj}`] = FilterParams[obj]
          columns.push(obj)
        }
      }
      if (apiResponse !== null && apiResponse?.count > 0 && filtervalueexists == 1) {
        var result = apiResponse.data;
        let filteredobj;
        if (columns.length > 0) {
          if (!IsFilterByInclude) {
            result = result.filter(function (item) {
              if (item[columns[0]] == filterfs[columns[0]]) {
                return true;
              }
              return false;
            });
          } else {
            result = result.filter(function (item) {
              if (item[columns[0]].includes(filterfs[columns[0]])) {
                return true;
              }
              return false;
            });
          }
        }
        if (columns.length > 1) {
          result = result.filter(function (item) {
            if (item[columns[1]] == filterfs[columns[1]]) {
              return true;
            }
            return false;
          });
        }
        if (setheaderFilterData !== undefined) {
          const headerval = result.flatMap((x) => x.filtcreaterybykey);
          const uniqueArray = headerval.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          })
          setheaderFilterData(uniqueArray)
        }
        if (SearchText !== "") {
          if (apiResponse.filterColumns !== undefined) {
            const filtercolumns = apiResponse.filterColumns;
            var filterfs2 = {};
            for (let i = 0; i < filtercolumns.length; i++) {
              filterfs2[filtercolumns[i]] = SearchText;
            }
            result = result.filter(function (item) {
              for (var key in filterfs2) {
                if (item[key].toLowerCase() == filterfs2[key].toLowerCase()) {
                  return true;
                }
                else if (item[key].toLowerCase().includes(filterfs2[key].toLowerCase())) {
                  return true;
                }
              }
              return false;
            });
            filteredobj = result;
          }
          else if (apiResponse.filter === "contains") {
            filteredobj = result.filter(item => item.filterybykey.toLowerCase().indexOf(SearchText.toLowerCase()) > -1);
          }
          else {
            filteredobj = result.filter(item => item.filterybykey === SearchText);
          }
        }
        else {
          filteredobj = result;
        }
        setCurrentPageNo(number);
        setActivePageData(filteredobj.slice(((number - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), ((number - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)));
      }
    }
    else {
      var result = apiResponse.data;
      let filteredobj = result;
      if (apiResponse.filterColumns !== undefined) {
        const filtercolumns = apiResponse.filterColumns;
        var filterfs = {};
        for (let i = 0; i < filtercolumns.length; i++) {
          filterfs[filtercolumns[i]] = SearchText;
        }
        result = result.filter(function (item) {
          for (var key in filterfs) {
            if (item[key]?.toLowerCase() == filterfs[key]?.toLowerCase()) {
              return true;
            }
            else if (item[key]?.toLowerCase().includes(filterfs[key]?.toLowerCase())) {
              return true;
            }
          }
          return false;
        });
        filteredobj = result;
      }
      else if (apiResponse.filter === "contains") {
        filteredobj = apiResponse?.data?.filter(item => item.filterybykey.toLowerCase().indexOf(SearchText.toLowerCase()) > -1);
      }
      else {
        filteredobj = apiResponse?.data?.filter(item => item.filterybykey === SearchText);
      }
      setCurrentPageNo(number);
      setActivePageData(filteredobj.slice(((number - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)), ((number - 1) * (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)) + (showNumberOfRows > 0 ? showNumberOfRows : TABLE_TOTAL_ROWS)));
    }
  };

  const TypeToChartComponent = {
    table: ({
      pageNo,
      setPageNo,
      setItems,
      items,
      onPageNoChange,
      onItemChange,
      searchText,
      setSearchText,
      onSearchBoxChange,
      resultSet,
      totalRecords,
      showPagination,
      showPaginationSummary,
      showSearchTextBox,
      isRefresh,
      setIsRefresh,
      widjetName,
      contextData,
      tableCaption,
      minimumRows,
      setShowModal,
      setSelectedItem,
      cardName,
      title,
      downloadExcel,
      handlePlusButtonClick,
      showPlusModal,
      setShowPlusModal,
      deleteSubscriber,
      editSubscriber,
      apiUrl,
      summaryTooltip,
      showCount,
      summaryBesideText,
      loadedCardId
    }) => {
      return renderTable(
        pageNo,
        setPageNo,
        setItems,
        items,
        onPageNoChange,
        onItemChange,
        searchText,
        setSearchText,
        onSearchBoxChange,
        resultSet,
        totalRecords,
        showPagination,
        showPaginationSummary,
        showSearchTextBox,
        isRefresh,
        setIsRefresh,
        widjetName,
        contextData,
        tableCaption,
        minimumRows,
        setShowModal,
        setSelectedItem,
        cardName,
        title,
        downloadExcel,
        handlePlusButtonClick,
        showPlusModal,
        setShowPlusModal,
        deleteSubscriber,
        editSubscriber,
        apiUrl,
        summaryTooltip,
        showCount,
        summaryBesideText,
        loadedCardId
      );
    },
  };

  const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
    .map((key) => ({
      [key]: React.memo(TypeToChartComponent[key]),
    }))
    .reduce((a, b) => ({ ...a, ...b }));

  const component = TypeToMemoChartComponent['table'];
  const contextData = useContext(AppContext);

  var totalRecords = summaryCount > 0 ? summaryCount : "";
  var show = false;

  show = showPagination;

  const renderTable = (
    pageNo,
    setPageNo,
    setItems,
    items,
    onPageNoChange,
    onItemChange,
    searchText,
    setSearchText,
    onSearchBoxChange,
    resultSet,
    totalRecords,
    showPagination,
    showPaginationSummary,
    showSearchTextBox,
    isRefresh,
    setIsRefresh,
    widjetName,
    contextData,
    tableCaption,
    minimumRows,
    setShowModal,
    setSelectedItem,
    cardName,
    title,
    downloadExcel,
    handlePlusButtonClick,
    showPlusModal,
    setShowPlusModal,
    deleteSubscriber,
    editSubscriber,
    apiUrl,
    summaryTooltip,
    loadedCardId,
    pageName
  ) => {

    let tableHeader = [];
    let tableData = [];

    resultSet = apiResponse
    tableHeader = resultSet.headers

    let alertMessageDetails = resultSet?.messageDetails ?? [];

    resultSet = apiResponse
    tableData = resultSet.data;

    if (tableData !== undefined && tableData.length > 0) {
      tableData.forEach((data, index) => {
        let hasToggle = checkArrayElementsAreSame(
          Object.keys(data),
          tbRowWithToggle
        );
        let hasCheckbox = checkArrayElementsAreSame(
          Object.keys(data),
          tbRowWithCheckBox
        );
        if (hasToggle) {
          data.isToggle = false;
        }
        if (hasCheckbox) {
          data.isCheckBox = false;
        }
      });
    }

    const createTooltip = (t, tooltipKey) => {
      if (tooltipKey !== "") {
        return (
          <OverlayTrigger
            placement="right"
            trigger={["hover", "focus"]}
            overlay={
              <Tooltip>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(tooltipKey, {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></div>
              </Tooltip>
            }
          >
            <Button variant="white" className="btn-help border-0 p-2">
              <i className="far fa-question-circle lg"></i>
            </Button>
          </OverlayTrigger>
        );
      }
    }

    return (
      <>
        {summaryCount > 0 &&
          <div className="card-merices-container">
            <div className="mt-1 mb-2 card-merices">
              <span className="metrics">{showCount ? formatCount(summaryCount) : ""}</span> {summaryBesideTextValue}
              { tooltipText && createTooltip(t, tooltipText)}
            </div>
          </div>}
        {(successMessage || errorMessage) && <div className="fade modal-backdrop show"></div>}
        <Alert
          className="toast-custom"
          variant="success"
          show={successMessage ? true : false}
          onClose={() => onCloseSuccessMessage()}
        >
          <div className="d-flex justify-content-between">
            <div>
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              {successMessage}
            </div>
            <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
          </div>
        </Alert>
        <Alert
          className="toast-custom"
          variant="danger"
          show={errorMessage ? true : false}
          onClose={() => onCloseErrorMessage()}
        >
          <div className="d-flex justify-content-between">
            <div>
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              {errorMessage}
            </div>
            <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
          </div>
        </Alert>
        {summaryCount > 0 ?
          <TableComponent
            handleEditCancel={handleEditCancel}
            setIsMultiDeleteDisabled={setIsMultiDeleteDisabled}
            onEditEvent={onEditEvent}
            setTableDataForDelete={setTableDataForDelete}
            setCheckDocument={setCheckDocument}
            setAllDocumentChecked={setAllDocumentChecked}
            checkedDocument={checkedDocument}
            allDocumentChecked={allDocumentChecked}
            onDocumentDownload={onDocumentDownload}
            onDocumentEdit={onDocumentEdit}
            onDeleteDocument={onDeleteDocument}
            setApiResponseMaster={setApiResponseMaster}
            apiResponseMaster={apiResponseMaster}
            onSCReportItemClick={onSCReportItemClick}
            tableHeaderData={tableHeader}
            tableBodyData={activepagedata}
            pageNo={currentpageno}
            setPageNo={setPageNo}
            onSortingChange={onSortingChange}
            onPageNoChange={onPageNoChangeChart}
            onEventchange={onEventchange}
            openPopupEvent={openPopupEvent}
            onItemChange={onItemChange}
            showPagination={showPagination}
            showPaginationSummary={showPagination}
            searchText={searchText}
            setSearchText={setSearchText}
            showSearchTextBox={false}
            onSearchBoxChange={onSearchBoxChange}
            totalRecords={totalRecords}
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
            setItems={setItems}
            items={items}
            tableCaption={tableCaption}
            minimumRows={minimumRows}
            setShowReportDetailModal={setShowModal}
            setSelectedItem={setSelectedItem}
            cardName={cardName}
            title={title}
            downloadExcel={downloadExcel}
            handlePlusButtonClick={handlePlusButtonClick}
            showPlusModal={showPlusModal}
            setShowPlusModal={setShowPlusModal}
            deleteSubscriber={deleteSubscriber}
            editSubscriber={editSubscriber}
            summaryBesideText={summaryBesideText}
            GenerateDigestPdf={GenerateDigestPdf}
            loadedCardId={loadedCardId}
            alertMessageDetails={alertMessageDetails}
          /> : <div className="card-no-data ">{t("header.NoDataFound")}</div>}
      </>
    );
  };

  const renderChart =
    (Component) =>
      ({
        resultSet,
        cardProperty,
        totalRecords,
        pageNo,
        setPageNo,
        items,
        setItems,
        onItemChange,
        onPageNoChange,
        showSearchTextBox,
        searchText,
        setSearchText,
        onSearchBoxChange,
        legends,
        show,
        isRefresh,
        setIsRefresh,
        widjetName,
        setd3,
        contextData,
        tableCaption,
        minimumRows,
        setShowModal,
        setSelectedItem,
        cardName,
        title,
        downloadExcel,
        handlePlusButtonClick,
        deleteSubscriber,
        editSubscriber,
        apiUrl,
        summaryBesideText,
        loadedCardId
      }) => {
        return (
          <>
            {apiResponse &&
              <Component
                resultSet={resultSet}
                cardProperty={cardProperty}
                pageNo={pageNo}
                setPageNo={setPageNo}
                onPageNoChange={onPageNoChange}
                items={items}
                setItems={setItems}
                onItemChange={onItemChange}
                showSearchTextBox={showSearchTextBox}
                searchText={searchText}
                onSearchBoxChange={onSearchBoxChange}
                totalRecords={totalRecords}
                legends={legends}
                showPagination={show}
                setSearchText={setSearchText}
                isRefresh={isRefresh}
                setIsRefresh={setIsRefresh}
                widjetName={widjetName}
                setd3={setd3}
                contextData={contextData}
                tableCaption={tableCaption}
                minimumRows={minimumRows}
                setShowModal={setShowModal}
                setSelectedItem={setSelectedItem}
                cardName={cardName}
                title={title}
                downloadExcel={downloadExcel}
                handlePlusButtonClick={handlePlusButtonClick}
                deleteSubscriber={deleteSubscriber}
                editSubscriber={editSubscriber}
                apiUrl={apiUrl}
                summaryBesideText={summaryBesideText}
                loadedCardId={loadedCardId}
              />
            }
            {!apiResponse &&
              showCardLoader == undefined && <div className={`preloader loader-center-align  ${loader ? "" : "show fff"}`}>
                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
              </div>
            }
          </>
        );
      };

  ChartRendererTable.propTypes = {
    cubejsApi: PropTypes.object,
    cardProperty: PropTypes.object,
    pageNo: PropTypes.number,
    setPageNo: PropTypes.func,
    onPageNoChange: PropTypes.func,
    onItemChange: PropTypes.func,
    searchText: PropTypes.string,
    onSearchTextChange: PropTypes.func,
    legends: PropTypes.object,
    handlePlusButtonClick: PropTypes.func,
    showPlusModal: PropTypes.bool,
    setShowPlusModal: PropTypes.func,
    deleteSubscriber: PropTypes.func,
    editSubscriber: PropTypes.func,
    apiUrl: PropTypes.string,
    showNumberOfRows: PropTypes.number,
    summaryTooltip: PropTypes.string,
    showCount: PropTypes.bool,
    summaryBesideText: PropTypes.string,
    showOthersColume: PropTypes.Object,
    item: PropTypes.Object,
    loadedCardId: PropTypes.number
  };

  ChartRendererTable.defaultProps = {
    cubejsApi: null,
    cardProperty: {},
    pageNo: 1,
    onPageNoChange: null,
    handlePlusButtonClick: null,
    onItemChange: null,
    searchText: "",
    onSearchTextChange: null,
    legends: {},
    apiUrl: "",
    showNumberOfRows: 0,
    summaryTooltip: "",
    showCount: true,
    summaryBesideText: "",
    showOthersColume: {},
    item: {},
    loadedCardId: 0
  };

  return (
    component &&
    renderChart(component)({
      cardProperty,
      totalRecords,
      pageNo,
      setPageNo,
      items,
      setItems,
      onItemChange,
      onPageNoChange,
      showSearchTextBox,
      searchText,
      setSearchText,
      onSearchBoxChange,
      legends,
      show,
      isRefresh,
      setIsRefresh,
      widjetName,
      setd3,
      contextData,
      tableCaption,
      minimumRows,
      setShowModal,
      setSelectedItem,
      cardName,
      title,
      downloadExcel,
      handlePlusButtonClick,
      showPlusModal,
      setShowPlusModal,
      deleteSubscriber,
      editSubscriber,
      apiUrl,
      showCount,
      summaryBesideText,
      item,
      loadedCardId
    })
  );

};
export default ChartRendererTable;
