import React, { useState, useEffect, useContext, useMemo } from 'react';
import { withRouter } from "react-router";
import { Row, Col, Image } from '@themesberg/react-bootstrap';
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const WebcastManageUsers = ({ children, setSelectedevent, setSelectedPresentation }) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [updateKey, setUpdateKey] = useState("");
  const [manageEventsNew, setManageEventsNew] = useState("");
  const [selectedPresentionNew, setSelectedPresentionNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false)
  const [eventPresentationsList, setEventPresentationsList] = useState([])
  const [blurLoader, setBlurLoader] = useState(false);

  useEffect(() => {
    // Fetching values from Local storage in case of Page Refresh
    if (localStorage.getItem("IsPageRefresh") == "1") {
      if (contextData && contextData.hasEvents == 1) {
        if (localStorage.getItem("webcastEventId")) {
          setSelectedevent(JSON.parse(localStorage.getItem("webcastEventId")));
        }
      } else {
        setSelectedPresentionNew(JSON.parse(localStorage.getItem("presentationId")))
      }
    }
  }, []);

  useEffect(() => {
    if (eventPresentationsList != null && eventPresentationsList.length) {
      if (contextData.hasEvents === 1) {
        let presentationsData = eventPresentationsList.filter((e) => { return e["Presentations.eventid"] === manageEventsNew["Events.id"] });
        renderWebsiteProfiles({ resultSet: presentationsData })
      } else {
        renderWebsiteProfiles({ resultSet: eventPresentationsList })
      }
    }
  }, [manageEventsNew, eventPresentationsList]);

  useEffect(() => {
    if (contextData.companyId > 0) {
      getWebsites();
    }
  }, [contextData.userId, contextData.companyId]);

  const onWebsiteDropdownChange = (data) => {
    setManageEventsNew(data);
  };

  const onPresentationDropdownChange = (data) => {
    setSelectedPresentionNew(data);
  };

  const onEventsDropdownChange = (data) => {
    setManageEventsNew(data);
  };

  const getWebsites = () => {
    setBlurLoader(true)
    request(baseURL + `/Common/GetWebcastPresentationByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setBlurLoader(false)
        let resultData = [];
        let presentationsData = [];
        if (res !== null) {
          resultData = res.Events.map((e) => ({ ["Events.title"]: e.EventTitle, ["Events.id"]: e.EventId }))
          presentationsData = res.EventPresentations.map((e) => ({ ["Presentations.name"]: e.Name, ["Presentations.id"]: e.Slug, ["Presentations.clientslug"]: e.ClientId, ["Presentations.slug"]: e.Slug, ["Presentations.eventid"]: e.EventId, ["Presentations.url"]: e.Url, ["Presentations.clientid"]: e.ClientId }))
        }
        setEventPresentationsList(presentationsData)
        rendersite({ resultSet: resultData })
      })
      .catch((err) => {
      })
  }

  const applyFilters = () => {
    setBlurLoader(false)
    if (contextData && contextData.hasEvents === 1) {
      contextData.changeEventId(manageEventsNew["Events.id"]);
      setSelectedevent(manageEventsNew);
      localStorage.setItem("webcastEventId", JSON.stringify(manageEventsNew));// Adding EventId to local stoarge in case of Page Refresh
    } else {
      localStorage.setItem("presentationId", JSON.stringify(selectedPresentionNew));
      setSelectedPresentation(selectedPresentionNew)
    }
  }

  const cancelFilters = () => {
    if (contextData && contextData.hasEvents === 1) {
      onWebsiteDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
    } else {
      onPresentationDropdownChange(JSON.parse(localStorage.getItem("PresentationId")))
    }
  }

  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="webcast" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Events', data: JSON.parse(localStorage.getItem("eventsData")), selectedItem: manageEventsNew, isVisible: true, label: t("header.filterEventLabel"), callback: onEventsDropdownChange, displayText: "Events.title" }
  ]}
    title={t("header.manageusersTitle")} showDate={false} applyFilters={applyFilters} />, [updateKey, manageEventsNew, autoLoad]);

  const commonHeaderForIL = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="webcast" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Presenration', data: JSON.parse(localStorage.getItem("presentationData")), selectedItem: selectedPresentionNew, isVisible: true, label: t("header.filterPresentationLabel"), callback: onPresentationDropdownChange, displayText: "Presentations.name" }
  ]}
    title={t("header.manageusersTitle")} showDate={false} applyFilters={applyFilters} />, [selectedPresentionNew, autoLoad]);

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (resultSet !== null && resultSet.length) {
      localStorage.setItem("eventsData", JSON.stringify(resultSet));
      if (JSON.parse(localStorage.getItem("webcastEventId")) !== null && Object.keys(JSON.parse(localStorage.getItem("webcastEventId"))).length > 0) {
        onEventsDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
      } else {
        onEventsDropdownChange(resultSet[0])
      }
      setUpdateKey(JSON.stringify(Math.random()))
      setAutoLoad(true)
    }
  }

  const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
    localStorage.setItem("presentationData", JSON.stringify([]));
    localStorage.setItem("presentationData", JSON.stringify(resultSet))
    if (resultSet !== null && resultSet.length) {
      if (selectedPresentionNew !== null && Object.keys(selectedPresentionNew).length <= 0) {
        if (localStorage.getItem("presentationId")) {
          onPresentationDropdownChange(JSON.parse(localStorage.getItem("presentationId")))
        } else {
          onPresentationDropdownChange(resultSet[0]);
        }
      }
      setUpdateKey(JSON.stringify(resultSet))
      setAutoLoad(true)
    }
  };

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {(contextData && contextData.hasEvents === 1) ? commonHeader : commonHeaderForIL}
      {
        contextData && contextData.hasEvents === 0 &&
        <Row className="ps-3">
          <Col xs={12} sm={12}>
            <p>{t("header.webcastManageUsersMessage")}
            </p>
          </Col>
        </Row>
      }

      <div className="d-inline-block p-3 pe-0 w-100">
        <div className="shadow-soft border rounded border-light w-100 nested-card-container webcast-manage-users  Notification-container">
          {children}
        </div>
      </div>
    </>
  );
};

export default withRouter(WebcastManageUsers);
